<template>
  <div class="home">
    <top-nav @menu_data="getMenuData" :isARClogo="isARClogo" />
    <div class="part1">
      <img src="./../../assets/imgs/app/arcanite/p1.png" class="part1Pic"/>
      <div class="part1Title">
        Arcanite
      </div>
      <div class="part1Content">
        Arcanite's frontend app is the must-have automation tool for on-the-go agents and developers. Stay connected, updated and on track by selling off-the-plan properties the Arcanite way!
      </div>
      <div>
        <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;">
          <div class="part1Button"><div class="part1Text">Book A Demo</div><img src='./../../assets/imgs/app/arcanite/vector.png' class="vector"></div>
        </a>
        <a  v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
      return false;">
          <div class="part1Button"><div class="part1Text">Book A Demo</div><img src='./../../assets/imgs/app/arcanite/vector.png' class="vector"></div>
        </a>
      </div>
    </div>
    <div class="part2RecoginizedIcons">
      <div class="part2-topcontent">
        <trusted></trusted>
      </div>
      <div class="part2-midcontent">
        <featuredIn></featuredIn>
      </div>
      <div class="part2-bottomcontent">
          <recognised></recognised>
      </div>
    </div>
    
    <div v-for="i,index in pageContent" :key="'part2'+index">
      <div class="part2" :style="i.backStyle">
        <div style="width:100%" >
        </div>
        <div class="part22Middle">
          <img :src="i.bottomPic" :style="i.bottomPicSize"/>
          <img :src="i.minicon" class="pic" />
          <div class="part2Title">
            {{i.title}}
          </div>
          <div class="part2Content">
            {{i.content}}
          </div>
          <div v-if="i.subImg" class="subImgPlace">
              <img :src="j" class="subImg" v-for="j,indexj in i.subImg" :key="'img'+indexj">
          </div>
          <div v-if="i.mini">
              <img :src="j" class="mini" v-for="j,indexj in i.mini" :key="'mini'+indexj">
          </div>
          <div v-if='i.subLine' class="part2Line"></div>
          <div class="part2subLine" v-for="j,indexj in i.subLine" :key="'subline'+indexj">
              <div class="dog"></div><div class="text">{{j}}</div>
          </div>
        </div>
        <!-- <img v-if="index==0" src="./../../assets/imgs/app/arcanite/back.png" class="part2Back"/> -->
      </div>
    </div>
    <div class="part5">
        <div class="title">
            Join the thousands of real estate professionals using Arcanite to streamline their teams everyday project marketing processes.
        </div>
        <div style="margin:auto;">
          <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true'); return false;">
            <div class="button">
                Book A Demo<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
            </div>
          </a>
          <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap'); return false;">
            <div class="button">
                Book A Demo<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
            </div>
          </a>
        </div>
    </div>
    <other-product></other-product>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
// import OtherProduct from '../PC/OtherProduct.vue';
export default {
  data() {
    return {
      isID:false,
      showMenuFlag: "0",
      isShow: false,
      isARClogo: true,
      part5MiddleNumber:2,
      // part5LeftNumber:1,
      // part5RightNumber:3,
      part5All:3,
      pageContent:[
        {title:'View your projects your way',
          minicon:require('@/assets/imgs/app/arcanite/minicon1.png'),
          content:'View all your current projects with quick links to availability, specifications, sharable information, marketing material and VR property rendering. Customise how you view your home screen for efficient property searches.',
        //   subLine:null,
          backStyle:{backgroundImage:'url('+require('./../../assets/imgs/app/arcanite/back.png')+')',backgroundSize:'100% 95%',backgroundRepeat:'no-repeat'},
          bottomPic:require('@/assets/imgs/app/arcanite/p2.png'),
          bottomPicSize:{width:'100%',position:'relative',top:'80px'}},
          {title:'All of your contacts in one place',
          minicon:require('@/assets/imgs/app/arcanite/minicon2.png'),
          content:'Instantly add and organise your contact list. Sort contacts into a range of varying categories and filter based on sales stage, client source and more.',
        //   subLine:null,
          bottomPic:require('@/assets/imgs/app/arcanite/p3.png'),
          bottomPicSize:{width:'95%'},
          subImg:[require('@/assets/imgs/app/arcanite/people1.png'),
          require('@/assets/imgs/app/arcanite/people2.png'),
          require('@/assets/imgs/app/arcanite/people3.png'),
          require('@/assets/imgs/app/arcanite/people4.png'),]},
          {title:'Track sales more efficiently than ever',
          backStyle:{marginBottom:'-50px'},
          minicon:require('@/assets/imgs/app/arcanite/minicon3.png'),
          content:'Track progress on all sales and filter searches based on current sales status. Sort by price and date, for efficient tracking and follow-ups.',
          subLine:['Filter and sort to suit your preferences','Oversee agent requests','View sales statistics for ongoing R&D'],
          bottomPic:require('@/assets/imgs/app/arcanite/p4.png'),
          bottomPicSize:{width:"96%",paddingLeft:'10px'}},
          {title:'Never miss an update',
          minicon:require('@/assets/imgs/app/arcanite/minicon4.png'),
          content:'Organise and add tasks to share amongst your team. Categorise each task for quick identification and use the inbuilt calendar to ensure all responsibilities are kept on track and send out reminder notifications.',
        //   subLine:null,
          bottomPic:null,
          bottomPicSize:null,
          backStyle:{marginBottom:'-50px'}
          // mini:[require('@/assets/imgs/app/arcanite/iconPeople.png'),
          // require('@/assets/imgs/app/arcanite/iconCal.png')]
          },
          {title:'All access to everything that matters',
          minicon:require('@/assets/imgs/app/arcanite/minicon5.png'),
          content:'Access saved lists, reports, invoices, partner contacts, training material, company information and much more. All you need to know to make a sale is in the palm of your hand.',
        //   subLine:null,
          bottomPic:null,
          bottomPicSize:null,
          // backStyle:{marginBottom:'50px'}
          // mini:[require('@/assets/imgs/app/arcanite/iconSlash.png'),
          // require('@/assets/imgs/app/arcanite/iconBook.png'),
          // require('@/assets/imgs/app/arcanite/iconSingle.png')]
          },
          ]
    };
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // this.handleScroll();
    // this.handleFirstPara();
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleClickDisplay() {
      this.isMove = true;
    },
    changePic(p){
      this.part5MiddleNumber=p
    },
  },

  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "footer-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "menu-bar": (resolve) => require(["./MenuBar.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./Recognised.vue"], resolve),
    // "more-features":(resolve) => require(["./MoreFeatures.vue"], resolve),
    "other-product":(resolve) => require(["./OtherProduct.vue"], resolve),
    "featuredIn":(resolve) => require(["./FeaturedIn.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
.home{
  text-align: center;
  display: block;
  width: 100vw;
  text-align: center;
  overflow: hidden;
  max-width: 428px;
  min-width: 320px;
  margin: auto;
}

.part1{
  margin: auto;
  width: 100%;
  overflow-x: hidden;
  // height: 659px;
  padding-top: 100px;
  text-align: left;
  .part1Pic{
    margin: auto;
    width:100%;
    // width: 375px;
    // height: 342px;
  }
  .part1Title{
    margin: 0 26px 0 26px;
    // width: 323px;
    // height: 36px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 30px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  } 
  .part1Content{
    // margin: auto;
    margin: 0 26px 0 26px;
    // width: 323px;
    // height: 120px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
    padding-top:10px;
  }
  .part1Button{
    // display: flex;
      // margin-top:30px ;
    margin: 30px 0 55px 26px;
    width: 150px;
    // height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
    // display: flex;
    height: 22px;
    // margin-bottom: 55px;
  }
  .part1Text{
    width: 120px;
      flex-grow: 0;
      font-family: Poppins-Bold;
      font-size: 16px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
  }
}


.part2RecoginizedIcons{
  margin: 40px auto;
  // width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  gap: 30px;
}

.vector{
  width: 22px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 10px;
}

.part2{
  margin: auto;
  width: 100%;
  // height: auto;
  display: block;
  overflow-x: hidden;
  text-align: left;
  padding-bottom: 70px;
  // padding-top: 30px;
  .part22Middle{
    // width: 325px;
    margin: 0 16px 0 16px;
  }
  .part2Title{
    margin: auto;
    margin:0 10px 20px 10px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 26px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  .part2Content{
      margin: auto;
      // margin-left: 10px;
    // width: 100%;
    // height: 60px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
    margin:0 10px 10px 10px;
  }
  .subImgPlace{
    display: flex;
    // margin-left: 10px;
    img{
      width: 60px;
      height: 60px;
    }
  }
  .part2Line{
    margin: auto;
    margin:20px 10px 20px 10px;
    // width: 323px;
    height: 1px;
    background-color: #e6ebf2;
  }
  .subImg{
    width: 40px;
    height: 40px;
    flex-grow: 0;
    display: inline-block;
    // object-fit: contain;
    // border-radius: 50%;
  }
  .mini{
    width: 40px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    // padding: 10px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #fff;
    display: inline-block;
  }
  .part2subLine{
      margin: auto;
      margin-left: 10px;
    display: flex;
    .dog{
      margin:4px 10px 28px 0;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      flex-grow: 0;
      background-color: #ff8d5a;
    }
    .text{
      // height: 18px;
      margin-bottom: 22px;
      flex-grow: 1;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #314455;
    }
  }
}

.pic{
  margin-top: 10px;
  width: 80px;
  height: 80px;
}

.part5{
    margin: auto;
    width: 100%;
	min-height:311px;
    overflow: hidden;
    // height: auto;
    background-image:url('./../../assets/imgs/app/arcanite/p5.png');
    // background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    .title{
        margin: auto;
        padding-top: 26px;
        padding: 26px 26px 26px 26px;
        // margin-bottom: 30px;
        // width: 325px;
        // height: 160px;
        align-self: stretch;
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 26px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    .button{
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        // line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        width: 171px;
        height: 42px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // gap: 10px;
        border-radius: 5px;
        background-color: #062440;
        margin-bottom: 26px;
        margin-left: 26px;
    }
}

</style>

